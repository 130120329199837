import React from 'react'
import SEO from '../components/seo/seo'
import Disclaimer from '../components/disclaimer/disclaimer'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const GeneralPracticeLawPage = () => (
  <>
    <SEO 
      title="Services" 
      description="Claremont NH’s general practice lawyer Jim Feleen serves Keene and Sullivan County. 603.504.6507. Personal injury, medical malpractice. 603-862-6507."
      keywords={['lawyer', 'law office', 'attorney', 'James Feleen', 'Jim Feleen', 'Claremont', 'NH', 'New Hampshire', 'Claremont NH', 'personal injury', 'injury', 'accident', 'contract']} />
    <Banner />
    <div className="body-wrapper">
      <h2>General Practice Law</h2>
      <h4>An attorney for personal injury, auto accidents, medical malpractice, contract matters and more.</h4>
      <p>
        Our law firm is equipped to respond to our clients’ many general law practice needs, including arranging for the involvement of specialized attorneys or experts.
      </p>
      <p>
        Typical cases we handle in the general practice area include personal injury claims, accident cases, medical malpractice, and contract matters. We deliver high quality services at affordable rates right here in Claremont, NH.
      </p>
      <h4>Examples of General Practice Services</h4>
      <ul>
        <li>Personal Injury</li>
        <li>Automobile Accidents</li>
        <li>Medical Malpractice</li>
        <li>Contract Matters</li>
        <li>Wrongful Death</li>
      </ul> 
    </div>
    <Disclaimer />
  </>
)

const Banner = props => (
  <StaticQuery
    query={query}
    render={data => (
      <div className="body-wrapper">
        <Img alt="Bookshelf full of legal books" className="hero-image" fluid={data.file.childImageSharp.fluid}/>
      </div>
    )}
  />
);

const query = graphql`
  query {
    file(relativePath: { eq: "bookcase-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default GeneralPracticeLawPage
